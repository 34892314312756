//Смена заголовка и видео
const VIDEO = document.getElementById('video')
const HEADLINE = document.getElementById('main__headline')
let url = location.href;
let urlCopy = new URL(url);
let headline = urlCopy.searchParams.get('headline');
let video = urlCopy.searchParams.get('video')
let image = urlCopy.searchParams.get('image')
if (headline !== null) {
    HEADLINE.innerHTML = headline
}
if (video !== null ) {
    if (video !== '') {
        VIDEO.querySelector('source').src = `video/${video}`
        VIDEO.load()
        VIDEO.play()
    }
} else {
    VIDEO.querySelector('source').src = `video/video.mov`
    VIDEO.load()
    VIDEO.play()
}
if (image !== null) {
    VIDEO.setAttribute('poster', `img/${image}`)
}


//Инициализация задержки анимации
import {CreateCompanyList} from "./CreateCompanyList";

new WOW().init();

//Маска ввода телефона
$(document).ready(function () {
    $(".phone-mask").mask("9 (999) 999-99-99");
});

//Прикрепить файл
$(document).ready(function () {
    $("#input__file").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name").html(filename);
    });
});

$(document).ready(function () {
    $("#input__file-2").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name-2").html(filename);
    });
});

$(document).ready(function () {
    $("#modal-window__file").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#modal-window-name").html(filename);
    });
});

//Слайдер
$('.clients__slider').slick({
    infinite: true,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                arrows: false,
            }
        }
    ]
});

const MOBILE_MENU = document.querySelector('.mobile')
const HTML = document.querySelector('html')
const MODAL_HEADLINE_WITH_FILE = document.getElementById('with-file__headline')
const MODAL_SUBTITLE_WITH_FILE = document.getElementById('with-file__subtitle')
const MODAL_HEADLINE_WITHOUT_FILE = document.getElementById('without-file__headline')
const MODAL_SUBTITLE_WITHOUT_FILE = document.getElementById('without-file__subtitle')
const TAB_DESCRIPTION = document.querySelector('.product__tab-description').querySelector('.description')
const PRODUCT_STANDARD = document.querySelector('.product__standard')
const PRODUCT_IMAGE = document.querySelector('.product__tab-image').querySelector('img')
const TAB_SELECT = document.querySelectorAll('.select')
const DECOR = document.querySelectorAll('.decor')
const PRODUCTION_DESCRIPTION = document.querySelector('.production__description-right')
const WORK_ITEM = document.querySelectorAll('.work__card-item')
const WORK_HEADLINE = document.querySelector('.work__description').querySelector('.headline')
const WORK_DESCRIPTION = document.querySelector('.work__description').querySelector('.description')
const FOOTER_ACTION = document.querySelector('.footer__action')
const COMPANY_LIST = document.getElementById('company-list')
const MAP_CITY = document.querySelectorAll('.map__city')
const COMPANY_BLOCK = document.querySelector('.company')
const CERT_GALLERY = document.querySelector('.image-full')

window.addEventListener('click', (event) => {
    let element = event.target

    //Мобильное меню
    if (element.classList.contains('menu-btn__open')) {
        if (MOBILE_MENU.classList.contains('active')) {
            closeMenuBg()
        } else {
            openMenuBg()
            HTML.style.overflowY = 'hidden'
        }
        MOBILE_MENU.classList.toggle('active')
    }

    if (element.classList.contains('mobile-close') ||
        element.classList.contains('mobile__nav-link') ||
        element.classList.contains('mobile__contact-link') ||
        element.classList.contains('menu-bg')) {
        MOBILE_MENU.classList.remove('active')
        closeMenuBg()
    }

    // Открытие модального окна без передачи файла
    if (element.classList.contains('no-file')) {
        MODAL_HEADLINE_WITHOUT_FILE.innerText = element.getAttribute('data-headline')
        MODAL_SUBTITLE_WITHOUT_FILE.innerText = element.getAttribute('data-subtitle')
        openModalBg()
        modalWithOutFile()
    }

    // Открытие модального окна с передачей файла
    if (element.classList.contains('file')) {
        MODAL_HEADLINE_WITH_FILE.innerText = element.getAttribute('data-headline')
        MODAL_SUBTITLE_WITH_FILE.innerText = element.getAttribute('data-subtitle')
        openModalBg()
        modalWithFile()
    }

    if (element.classList.contains('cert-gallery')) {
        CERT_GALLERY.click()
    }

    // Закрытие модального окна
    if (element.classList.contains('close-btn') ||
        element.classList.contains('modal-bg') ||
        element.classList.contains('button-ok')) {
        closeAllModal()
    }

    if (element.classList.contains('select')) {
        TAB_DESCRIPTION.classList.add('invisible')
        PRODUCT_IMAGE.classList.add('invisible')
        PRODUCT_STANDARD.classList.add('invisible')
        setTimeout( () => {
            TAB_DESCRIPTION.innerHTML = element.getAttribute('data-description')
            PRODUCT_IMAGE.src = element.getAttribute('data-image')
            PRODUCT_STANDARD.innerHTML = element.getAttribute('data-standard')
            TAB_DESCRIPTION.classList.remove('invisible')
            PRODUCT_IMAGE.classList.remove('invisible')
            PRODUCT_STANDARD.classList.remove('invisible')
        }, 300)

        TAB_SELECT.forEach( (item) => {
            item.classList.remove('active')
        })

        element.classList.add('active')

    }

    if (element.classList.contains('decor')) {

        DECOR.forEach( (item) => {
            item.classList.remove('active')
        })
        element.classList.add('active')

        PRODUCTION_DESCRIPTION.classList.add('invisible')
        setTimeout(() => {
            PRODUCTION_DESCRIPTION.innerHTML = element.getAttribute('data-description')
            PRODUCTION_DESCRIPTION.classList.remove('invisible')
        }, 300)
    }

    if (element.classList.contains('work__card-item')) {

        WORK_ITEM.forEach( (item) => {
            item.classList.remove('active')
        })

        element.classList.add('active')

        WORK_DESCRIPTION.classList.add('invisible')
        WORK_HEADLINE.classList.add('invisible')
        setTimeout(() => {
            WORK_HEADLINE.innerHTML = element.getAttribute('data-headline')
            WORK_DESCRIPTION.innerHTML = element.getAttribute('data-description')
            WORK_HEADLINE.classList.remove('invisible')
            WORK_DESCRIPTION.classList.remove('invisible')
        }, 300)

    }

    if (element.classList.contains('map__city')) {
        MAP_CITY.forEach( (MAP_CITY) => {
            MAP_CITY.classList.remove('active')
        })
        element.classList.add('active')
        COMPANY_BLOCK.classList.add('invisible')
        setTimeout(() => {
            renderCompanyList()
            COMPANY_BLOCK.classList.remove('invisible')
        }, 300)
    }

    if (element.classList.contains('next-region')) {
        let setCurrentRegion

        for (let i = 0; i < MAP_CITY.length; i++) {
            if (MAP_CITY[i].classList.contains('active')) {
                setCurrentRegion = i
            }
        }

        if (setCurrentRegion === MAP_CITY.length - 1) {
            MAP_CITY[0].click()
        } else {
            setCurrentRegion++
            MAP_CITY[setCurrentRegion].click()
        }

    }

})

if (innerWidth < 767) {
    window.addEventListener('scroll', () => {
        if ( pageYOffset > 400) {
            FOOTER_ACTION.classList.add('active')
        } else {
            FOOTER_ACTION.classList.remove('active')
        }
    })
}


//Функции по открытию и закрытию фона для мобильного меню
function openMenuBg() {
    $('.menu-bg').fadeIn()
}
function closeMenuBg() {
    $('.menu-bg').fadeOut()
    HTML.removeAttribute('style')
}


// Открытие модального окна
function openModalBg() {
    $('.modal-bg').fadeIn()
}

function modalWithOutFile() {
    $('.without-file').fadeIn()
}


function modalWithFile() {
    $('.with-file').fadeIn()
}

function closeAllModal() {
    $('.modal-bg').fadeOut()
    $('.without-file').fadeOut()
    $('.with-file').fadeOut()
    $('.modal-send').fadeOut()
}


function renderCompanyList() {
    COMPANY_LIST.innerHTML = ''
    let companyList = new CreateCompanyList()
    COMPANY_LIST.append(companyList.renderCompanyList())
}

renderCompanyList()

//Формирование формы аналитики
$(document).ready(function () {
    let url_string = location.href;
    let url = new URL(url_string);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_meduim');
    let utm_compaign = url.searchParams.get('utm_compaign');
    let utm_content = url.searchParams.get('utm_content');
    let utm_term = url.searchParams.get('utm_term');
    let yclid = url.searchParams.get('yclid');
    let gclid = url.searchParams.get('gclid');
    let pm_position = url.searchParams.get('pm_position');
    let keyword = url.searchParams.get('keyword');
    let clientid;
    let googlecid;

    $('.analytics input[name="utm_source"]').val(utm_source);
    $('.analytics input[name="utm_medium"]').val(utm_medium);
    $('.analytics input[name="utm_compaign"]').val(utm_compaign);
    $('.analytics input[name="utm_content"]').val(utm_content);
    $('.analytics input[name="utm_term"]').val(utm_term);
    $('.analytics input[name="yclid"]').val(yclid);
    $('.analytics input[name="gclid"]').val(gclid);
    $('.analytics input[name="pm_position"]').val(pm_position);
    $('.analytics input[name="keyword"]').val(keyword);

    if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
        googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
    } else {
        googlecid = 'GA - отсуствует'
        $('.analytics input[name="googlecid"]').val(googlecid);
    }

    if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
        clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
        $('.analytics input[name="clientid"]').val(clientid[1]);
    } else {
        clientid = 'YM - отсуствует'
        $('.analytics input[name="clientid"]').val(clientid);
    }

});

const googleStat = document.getElementById('googleStat')
const yandexStat = document.getElementById('yandexStat')

function sendStats() {
    if (googleStat) {
        googleStat.click()
    }

    if (yandexStat) {
        yandexStat.click()
    }
}



//Передача лида и формирование формы аналитики
$(function () {
    $('form').submit(function (e) {
        e.preventDefault();
        let $form = $(this),
            formID,
            formData = new FormData(this);
        $("form#analytics").serializeArray().forEach(function (field) {
            formData.append(field.name, field.value)
        });
        formID = "#" + $form.attr('id') + " button";  // Формируем переменную с номер формы и добавляем селектор button
        $(formID).prop('disabled', true);
        $(formID).css({'opacity': 0.3});
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function () {
            let fileName = ''
            $('.without-file').fadeOut()
            $('.with-file').fadeOut()
            $('.modal-send').fadeIn();
            $('form').trigger('reset');
            $('.file-name').html(fileName)
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
            sendStats()
        });
    });
});


