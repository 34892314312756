export class CreateCompanyList {
    constructor() {

    }

    renderCompanyList() {
        const MAP_CITY = document.querySelectorAll('.map__city')
        const COMPANY_REGION = document.querySelector('.region-city').querySelector('.region')
        const COMPANY_CITY = document.querySelector('.region-city').querySelector('.city')

        let template =''
        let activeCityNumber

        for (let i = 0; i < MAP_CITY.length; i++) {
            if (MAP_CITY[i].classList.contains('active')) {
                activeCityNumber = i
            }
        }

        let dataCity = MAP_CITY[activeCityNumber].getAttribute('data-company').split(',')

        COMPANY_REGION.innerHTML = dataCity[0]
        COMPANY_CITY.innerHTML = dataCity[1]

        let companyList = document.createElement('ul')

        for (let i = 2; i < dataCity.length; i++) {
            template += `<li class="company-list__item">${dataCity[i]}</li>`
        }

        companyList.innerHTML = template

        return companyList
    }
}